//@boa 2021-04-10
//---------------

import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card,
         CardActionArea,
         CardActions,
         CardContent,
         CardMedia,
         Button,
         Box,
         Typography } from '@material-ui/core';
import Avatar from './avatar.js';
import './App.css';

const useStyles = makeStyles({
   root: {
      maxWidth: 345,
   },
   media: {
      height: 215,
      backgroundPosition: "center 10px",
      backgroundSize: "70%",
   },
});


class App extends Component {
   render() {
      const classes = this.props.classes;
      return(
         <React.Fragment>
            <Box display="flex" justifyContent="center" m={1} p={1} bgcolor="background.paper">
               <Card className={classes.root}>
                  <CardActionArea bgcolor="text.disabled">
                     <CardMedia
                        className={classes.media}
                        title="@MrBoa Avatar"
                     >
                        <Avatar />
                     </CardMedia>
                     <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                           @MrBoa
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                           Las Apps de @MrBoa
                        </Typography>
                     </CardContent>
                  </CardActionArea>
                  <CardActions>
                     <Button size="small" color="primary" onClick={() => { window.location.replace(`https://mrboa.com`) }}>
                        Web
                     </Button>
                     <Button size="small" color="primary" onClick={() => { window.location.assign(`https://webmail.mrboa.app`) }}>
                        Correo
                     </Button>
                     <Button size="small" color="primary">
                        Más
                     </Button>
                  </CardActions>
               </Card>
            </Box>
         </React.Fragment>
      );
   }
}

//export default App;
export default () => {
   const classes = useStyles();
   return (
      <App classes={classes} />
   )
}
